import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { config } from "../firebase/firebaseUtils";
import dispatch, { MiddlewareAction } from "../middleware";
import { getAdmin, getLenderSources } from "./admin.action";
import { getApplicationDocTypes } from "./application.action";
import { getUserDocTypes } from "./user.action";

export enum Actions {
  FIREBASE_LOGIN = "FIREBASE_LOGIN",
  FIREBASE_REGISTER = "FIREBASE_REGISTER",
  CLEARE_FIREBASE_RESPONSE = "CLEARE_FIREBASE_RESPONSE",
  FIREBASE_VERIFICATION_MAIL = "FIREBASE_VERIFICATION_MAIL",
  FIREBASE_PASSWORD_UPDATE = "FIREBASE_PASSWORD_UPDATE",
  STOP_LOADING = "STOP_LOADING",
  REQUEST_USER_LOGOUT = "REQUEST_USER_LOGOUT",
}
firebase.initializeApp(config, "LMS");
export function login(email: string, password: string, storeDispatch: any) {
  dispatch(storeDispatch, firebaseLogin());
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((response: any) => {
      if (!response.user.emailVerified) {
        dispatch(
          storeDispatch,
          firebaseLogin("FAILURE", {
            message: "You haven't verified your email.",
            code: "",
          })
        );
      } else {
        doAfterLoginSuccess(storeDispatch);
      }
    })
    .catch((error) => {
      dispatch(storeDispatch, firebaseLogin("FAILURE", error));
    });
}

export function firebaseLogin(
  type?: string,
  response?: object
): MiddlewareAction {
  return {
    actionName: `${Actions.FIREBASE_LOGIN}${type ? `_${type}` : ""}`,
    body: { response },
  };
}
export function firebaseRegister(
  type?: string,
  response?: object
): MiddlewareAction {
  return {
    actionName: `${Actions.FIREBASE_REGISTER}${type ? `_${type}` : ""}`,
    body: { response },
  };
}
export function register(email: string, password: string, storeDispatch: any) {
  dispatch(storeDispatch, firebaseRegister());
  firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((response: any) => {
      dispatch(storeDispatch, firebaseRegister("SUCCESS", response));
      sendVerificationMail(firebase.auth().currentUser, storeDispatch);
    })
    .catch((error) => {
      dispatch(storeDispatch, firebaseRegister("FAILURE", error));
    });
}

export function sendVerificationMail(currentUser: any, storeDispatch: any) {
  currentUser
    .sendEmailVerification()
    .then(() => {
      dispatch(storeDispatch, firebaseVerification("SUCCESS"));
    })
    .catch((error: any) => {
      dispatch(storeDispatch, firebaseVerification("FAILURE", error));
    });
}
export function firebaseVerification(
  type?: string,
  response?: object
): MiddlewareAction {
  return {
    actionName: `${Actions.FIREBASE_VERIFICATION_MAIL}${
      type ? `_${type}` : ""
    }`,
    body: { response },
  };
}
export function clearFirebaseResponse() {
  return { actionName: Actions.CLEARE_FIREBASE_RESPONSE };
}
export function sendResetPwEmail(email: string, storeDispatch: any) {
  dispatch(storeDispatch, firebaseVerification());
  firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
      dispatch(storeDispatch, firebaseVerification("SUCCESS"));
    })
    .catch((error) => {
      dispatch(storeDispatch, firebaseVerification("FAILURE", error));
    });
}
export function checkForAlreadyLogin(storeDispatch: any) {
  dispatch(storeDispatch, firebaseLogin());
  firebase.auth().onAuthStateChanged((user) => {
    if (user && user.emailVerified) {
      doAfterLoginSuccess(storeDispatch);
    } else {
      dispatch(storeDispatch, stopLoading());
      // Auto login didn't happen
    }
  });
}

export default function logoutUser(token: string): MiddlewareAction {
  return {
    url: "/v1/users/logout",
    type: "POST",
    actionName: Actions.REQUEST_USER_LOGOUT,
    token,
  };
}

async function getToken() {
  const token = await getIdToken();
  return token;
}

export async function logout(storeDispatch?: any) {
  try {
    const token = await getToken();
    await firebase.auth().signOut();
    await dispatch(storeDispatch, logoutUser(token));
    window.location.href = window.location.origin;
  } catch (_) {}
}

export async function changePassword(
  currentPassword: string,
  newPassword: string,
  storeDispatch: any
) {
  await dispatch(storeDispatch, {
    actionName: Actions.FIREBASE_PASSWORD_UPDATE,
  });
  const user = firebase.auth().currentUser;
  if (!user?.email) {
    await dispatch(storeDispatch, {
      actionName: `${Actions.FIREBASE_PASSWORD_UPDATE}_FAILURE`,
    });
    throw new Error("No logged in user found");
  }
  const credential = firebase.auth.EmailAuthProvider.credential(
    user.email,
    currentPassword
  );
  try {
    await user.reauthenticateWithCredential(credential);
    await user.updatePassword(newPassword);
    await dispatch(storeDispatch, {
      actionName: `${Actions.FIREBASE_PASSWORD_UPDATE}_SUCCESS`,
    });
    return { message: "Your password changed successfully" };
  } catch (error) {
    await dispatch(storeDispatch, {
      actionName: `${Actions.FIREBASE_PASSWORD_UPDATE}_FAILURE`,
    });
    throw new Error(
      error?.message ||
        "Your password can't be changed right now, Please Try again later"
    );
  }
}
export function stopLoading() {
  return { actionName: Actions.STOP_LOADING };
}
function doAfterLoginSuccess(storeDispatch: any) {
  dispatch(storeDispatch, firebaseLogin("SUCCESS"));
  dispatch(storeDispatch, getAdmin());
  dispatch(storeDispatch, getLenderSources());
  // Load the doc-type after login success and cache in Redux.
  // This data doesn't change thus calling it multiple time is redundent.
  dispatch(storeDispatch, getUserDocTypes());
  dispatch(storeDispatch, getApplicationDocTypes());
}
export async function getIdToken(forceRefresh?: boolean) {
  const currentUser = firebase.auth().currentUser;
  if (currentUser === null) {
    logout();
  }
  return currentUser?.getIdToken(forceRefresh) || "";
}
